import { setCookie, deleteCookie, getCookie, hasCookie } from 'cookies-next';
import { useEffect } from 'react';
import { GetServerSideProps, NextPage } from 'next';
import { useTranslation } from 'next-i18next';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';

import { ICountry, IJwtPayload } from '@rainhq/models';
import { decodeJwt } from '@rainhq/helpers';
import { captureException } from '@sentry/nextjs';

import { getCountries, getToken, requestSSOToken } from 'config/serverApi';
import { CookieName } from 'enums/cookies';
import { Language } from 'enums/language';
import { getResourceMap, ResourceMap } from 'services/contentful';
import { computeSupportURL, getRedirectDestination } from 'services/freshdesk';

import { SupportRouter as Layout } from 'library/layouts/SupportRouter';
import { Divider, Header, Title, SubTitle } from 'library/layouts/SupportRouter/SupportRouter.styles';
import { SignInRouter } from 'library/components/SignInRouter';
import { CountrySelectRouter } from 'library/components/CountrySelectRouter';

import { getEnvVars } from 'services/vars';

interface IProps {
  countries: ICountry[];
  resourceMap?: ResourceMap;
  resourceKey: string;
  isWebView: boolean;
}

const HomePage: NextPage<IProps> = ({ countries = [], resourceMap, resourceKey, isWebView }: IProps) => {
  const { t } = useTranslation(['common']);

  useEffect(() => {
    // STORE resourceKey in COOKIE (TO AVOID LOSING DURING LOGIN FLOW)
    setCookie(CookieName.MappingKey, resourceKey, { domain: `${getEnvVars().NEXT_PUBLIC_RAIN_DOMAIN || ''}` });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Layout isWebView={isWebView}>
      <Header data-testid={`support-header`}>
        <>
          <Title data-testid={`support-title`}>{t('sign-in-title')}</Title>
          <SubTitle data-testid={`support-subTitle`}>{t('sign-in-subtitle')}</SubTitle>
        </>
      </Header>
      <SignInRouter />
      <Divider>{t('or')}</Divider>
      <CountrySelectRouter countries={countries} resourceMap={resourceMap} resourceKey={resourceKey} />
    </Layout>
  );
};

export const getServerSideProps: GetServerSideProps = async context => {
  const { req, res, query, locale } = context;

  // GET QUERY STRING PARAMETERS
  // const returnTo = query.return_to || '';
  // const zendeskBrandId = query.brand_id || '';
  // const zendeskType = query.zendesk_type || '';
  // const zendeskId = query.zendesk_id || '';
  const isWebView = !!query.mobile || false;

  const nonce = (query.nonce as string) || '';
  const state = (query.state as string) || '';

  if (isWebView) {
    // cookie to hide header on zendesk support center theme
    setCookie(CookieName.IsWebView, isWebView, {
      domain: `${getEnvVars().NEXT_PUBLIC_RAIN_DOMAIN || ''}`,
      res,
      req,
    });
  } else {
    deleteCookie(CookieName.IsWebView, { domain: `${getEnvVars().NEXT_PUBLIC_RAIN_DOMAIN || ''}`, res, req });
  }

  // GET KEY FOR RESOURCE MAPPING
  let resourceKey = (query.key as string) || '';
  const recoveredKey = hasCookie(CookieName.MappingKey, { req, res })
    ? getCookie(CookieName.MappingKey, { req, res })
    : null;
  if (resourceKey === '' && typeof recoveredKey === 'string' && recoveredKey !== '') {
    resourceKey = resourceKey !== '' && typeof recoveredKey !== 'string' ? resourceKey : recoveredKey;
  }

  // GET CONTENTFUL MAPPING
  let resourceMap: ResourceMap | null = null;
  if (resourceKey && resourceKey !== 'contact') {
    resourceMap = await getResourceMap(resourceKey);
  }

  if (hasCookie('session', { req, res })) {
    try {
      const jwt = await getToken(req.headers.cookie);
      const jurisdiction = decodeJwt<IJwtPayload>(jwt)['rain:jurisdiction'];
      let ssoToken = '';

      if (nonce) {
        ssoToken = await requestSSOToken(nonce, jwt);
      }

      const destination = getRedirectDestination(
        true,
        jurisdiction,
        locale as Language,
        resourceMap,
        resourceKey,
        ssoToken,
        nonce,
        state,
      );

      if (resourceKey) {
        const supportURL = computeSupportURL(jurisdiction, locale as Language, resourceMap, resourceKey);
        setCookie('redirect_url', supportURL, {
          req,
          res,
          domain: `${getEnvVars().NEXT_PUBLIC_RAIN_DOMAIN || ''}`,
        });
      }

      if (destination) {
        // Before redirecting, delete the cookie that stores the mapping key
        deleteCookie(CookieName.MappingKey, { domain: `${getEnvVars().NEXT_PUBLIC_RAIN_DOMAIN || ''}`, res, req });
        return {
          redirect: {
            destination,
            permanent: false,
          },
          props: {},
        };
      }
    } catch (error: unknown) {
      captureException(error);
    }
  }

  const [countries, translations] = await Promise.all([
    getCountries(),
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    serverSideTranslations(context.locale!, ['common', 'errors']),
  ]);

  return {
    props: {
      countries,
      resourceMap,
      resourceKey,
      isWebView,
      ...translations,
    },
  };
};

export default HomePage;
